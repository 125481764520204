import React from "react"

import Layout from "../../layouts/default"
import Seo from "../../components/seo"
import Paragraph from "../../components/paragraph"
import Button from "../../components/button"
import Stack from "../../components/stack"
import Constrain from "../../components/constrain"
import Box from "../../components/box"
import Heading from "../../components/heading"
import Text from "../../components/text"
import Divider from "../../components/divider"
import TextLink from "../../components/textlink"

const RobotHoelderlinPage = () => (
  <Layout backdrop="philosophie-hoelderlin">
    <Seo title="Philosophie auf Knopfdruck: Hölderlin" />
    <Constrain>
      <Stack>
        <Stack space={3}>
          <Heading level={2}>Philosophie auf Knopfdruck</Heading>
          <Paragraph>
            Das Zitat ist von{" "}
            <Text as="strong" color="primary" sans>
              Hölderlin
            </Text>{" "}
            und stammt aus…
          </Paragraph>
        </Stack>

        <Stack space={6}>
          <Box bg="muted" p={[6, 8]}>
            <Stack space={3}>
              <Heading level={5}>
                Friedrich Hölderlin:{" "}
                <TextLink href="https://meiner.de/theoretische-schriften-14490.html">
                  Theoretische Schriften
                </TextLink>
              </Heading>
              <Text size={3}>
                hrsg. von Johann Kreuzer, Hamburg 2020 (= Philosophische
                Bibliothek Band 509)
              </Text>
            </Stack>
          </Box>
          <Text size={1} sans color="whisper">
            Mit freundlicher Genehmigung des Felix Meiner Verlags Hamburg.
          </Text>
        </Stack>

        <Divider size={4} />

        <Stack space={6}>
          <Paragraph>
            Ein Inhalt aus unserer Sonderausstellung ›Idealismusschmiede in der
            Philosophen-WG‹{" "}
          </Paragraph>
          <Paragraph as="strong" serif={false} sans>
            Mehr Digitales zu Hegel und Hölderlin?
          </Paragraph>
          <Button to="/sonderausstellungen/hegel-hoelderlin">
            Zur Sonderausstellung
          </Button>
        </Stack>
      </Stack>
    </Constrain>
  </Layout>
)

export default RobotHoelderlinPage
